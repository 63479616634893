import React, { useState } from 'react';
import './PopUp.css';


function PopUp() {
    const [open, setOpen] = useState(true);


    const handleCloseClick = () => {
        setOpen(false);
    }


    return (
        <div >
            {open && (
                <div id="popup" className="overlay">
                    <div className="popup">
                        <h3>Tack för allt</h3>
                        <p>Vi har stängt ner Hong Kong och inom kort kommer det nya ägare att ta över lokalerna.
                        </p>
                        <p>Vi vill ge ett stort tack till alla kära gäster som har kommit genom åren!
                        !</p>
                        <p>Mvh Personalen på Hong Kong</p>
                        <div className="content">
                            <button className="close content_btn" onClick={handleCloseClick}>Stäng</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default PopUp